import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/it/layout"
import SEO from "../../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import ReactTimeAgo from 'react-time-ago'
import JavascriptTimeAgo from 'javascript-time-ago'
import it from 'javascript-time-ago/locale/it'
import Button from "react-bootstrap/Button"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
      query {
          ebooks: allDirectusEbook(sort: {order: DESC, fields: release_date}) {
              nodes {
                  name
                  release_date
                  description
                  image {
                      localFile {
                          childImageSharp {
                              fluid {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                      }
                  }
                  file {
                      localFile {
                          publicURL
                      }
                  }
              }
          }
          image: file(relativePath: { eq: "ebooks.jpg" }) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  JavascriptTimeAgo.locale(it)

  const fixDateForAllBrowsers = dateString => dateString.replace(/-/g, '/');

  const seo = {
    title: "eBooks",
    description: "Scarica i nostri ebook",
  }

  return (
    <Layout>
      <SEO title={seo.title} description={seo.description} lang={"it"} url={"/ebooks"} />
      <Container fluid>
        <Row className={"site-content"}>
          <Col md={12} lg={6} xl={5} className={"offset-lg-1"}>
            <h1>eBooks</h1>
            { data.ebooks.nodes.map((ebook, key) => (
              <Card key={key} className={"my-3 ebook"}>
                <Row>
                  <Col xs={3} className={"pr-0"}>
                    <Img fluid={ebook.image.localFile.childImageSharp.fluid} style={{ width: `100%`, height: `100%` }}/>
                  </Col>
                  <Col xs={9} className={"pl-0"}>
                    <Card.Body>
                      <Card.Title className={"d-flex"}>
                        <span>{ ebook.name }</span>
                        { ebook.file.localFile ? (
                          <Button href={ebook.file.localFile.publicURL} target={"_blank"} size={"sm"} variant={"outline-primary"} className={"float-right"}>Scarica</Button>
                        ) : (<></>)}
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">Rilasciato <ReactTimeAgo date={ fixDateForAllBrowsers(ebook.release_date) } locale={"it"}/></Card.Subtitle>
                      <Card.Text className={"my-0"}>{ ebook.description }</Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            ))}
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5} className={"offset-lg-1 p-0"}>
            <Img fluid={data.image.childImageSharp.fluid} style={{ width: `100%` }} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
